<template>
  <div>

    <v-navigation-drawer  fixed app v-model="currentAnalysis.showNavDrawer" disable-resize-watcher v-if="currentUser">

      <v-list class="pa-1">
        <v-list-item>
          <router-link v-if="currentUser.username"
            :to="{ name: 'User Profile', params: { username: currentUser.username } }">
            <v-list-item-avatar>
              <img :src="avatarPic" v-if="currentUser.profilepic" />
              <img :src="avatarpic4user(currentUser)" v-else />
            </v-list-item-avatar>
          </router-link>
          <v-list-item-content>
            <v-list-item-title>
              <b v-if="currentUser.username"
                :to="{ name: 'User Profile', params: { username: currentUser.username } }">{{ currentUser.firstname }}
                {{ currentUser.lastname }}</b>
              <router-link class="pl-1" :to="{ name: 'Edit Profile' }">
                <v-icon small>edit</v-icon>
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="pt-0" dense nav>

        <div v-for="(item, index) in computeditems" :key="index">
          <!--<template v-if="item.to != '/'">-->
          <v-divider class="mt-2" :key="item.to" v-if="item.title == 'divider'"></v-divider>
          <v-subheader v-if="item.title == 'divider'">{{ item.to }}</v-subheader>
          <v-list-item v-else-if="item.action" @click="item.action">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else :to="item.to == '#' ? '' : item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--</template>-->
        </div>


        <v-list-item key="logout" @click="logout">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer  v-model="currentAnalysis.showNavDrawer" fixed app disable-resize-watcher v-else>
      <v-list class="pa-1">
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>account_circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Anonymous</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="pt-0" dense>
        <v-list-item v-for="item in itemsanon" :key="item.title" :to="item.to == '#' ? '' : item.to">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="currentAnalysis.pcapid" v-model="currentAnalysis.showAnalysisDrawer" disable-resize-watcher
      fixed app right width="600" height="100%"
      v-show="$route.name === 'Analysis' || $route.path === '/auth/login' ? true : false">
      <analysisrecipe></analysisrecipe>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="currentAnalysis.debug" v-model="currentAnalysis.debug" disable-resize-watcher fixed app
      right width="600" height="100%" v-show="$route.name === 'Analysis' || $route.path === '/auth/login' ? true : false">
      <analysisdebug></analysisdebug>
    </v-navigation-drawer>

    <v-app-bar app dark color="grey darken-3" dense>
      <v-app-bar-nav-icon
        @click.stop="currentAnalysis.showNavDrawer = !currentAnalysis.showNavDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="ml-0 pl-4">
        <template v-if="currentAnalysis.pcap && currentAnalysis.pcap.origname">
          <v-tooltip bottom>

            <template v-slot:activator="{ on }">
              <router-link class="navigation-pcap-link"
                :to="{ name: 'PCAPs', params: { query: '_id:' + currentAnalysis.pcapid, type: 'search' } }"><span
                  v-on="on">{{ currentAnalysis.pcap.origname | cleanFilename }}</span></router-link>
            </template>
            <span>{{ currentAnalysis.pcap.origname }}</span>
          </v-tooltip>
        </template>
        <template v-else>{{ $route.name }}</template>
      </v-toolbar-title>


      <!--<v-icon>chevron_right</v-icon>-->
      <!--<v-toolbar-title class="pl-1 white--text">{{ $route.params.id }}</v-toolbar-title>-->
      <!--<v-toolbar-title class="pl-1 white--text">{{pcap.origname}}</v-toolbar-title>-->
      <template v-if="$route.name === 'Analysis'">
        <filterbox class="pb-2" v-model="currentAnalysis.filter" :filterok="filterok"></filterbox>

        <!--<v-text-field full-width light solo v-model="displayfilter"  clearable class="px-3"
          v-on:keyup.enter="enterpressed"
          v-on:keyup.esc="escapepressed"
        >
        </v-text-field>-->
      </template>
      <template v-else>
        <v-spacer></v-spacer>
      </template>

      <v-toolbar-items>
        <template v-if="($route.name === 'Packet Analyzer' || $route.name === 'PCAPs')
          && !currentUser">
          <v-btn v-if="currentAnalysis.features.enablelogin" :to="{ name: 'Login' }" text small>Login
          </v-btn>

          <v-btn v-if="currentAnalysis.features.enableregistration" :to="{ name: 'Register' }" text small>Register
          </v-btn>

        </template>

        <template v-if="$route.name === 'Analysis'">

          <v-btn icon v-if="currentAnalysis.features.iograph && currentAnalysis.selectStartDate"
            @click.stop="resetAnalysis()">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon slot="activator" v-on="on" color="red">close</v-icon>
              </template>

              <span>Reset interval</span>
            </v-tooltip>
          </v-btn>


          <v-btn icon v-if="currentAnalysis.analysisid != null && currentAnalysis.analysisid != 'none'"
            @click.stop="nextAnalysisStep()">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">play_arrow</v-icon>
              </template>

              <span>Next analysisstep</span>
            </v-tooltip>
          </v-btn>


          <v-btn-toggle v-model="toggle_exclusive" mandatory>
            <v-btn icon @click.stop="displaypacketview()">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon slot="activator" color="green lighten-1" v-on="on">folder</v-icon>
                </template>

                <span>Show PacketView</span>
              </v-tooltip>
            </v-btn>

            <v-btn icon @click.stop="displayconnectionsview()">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon slot="activator" color="green lighten-1" v-on="on">dvr</v-icon>
                </template>

                <span>Advanced Connections</span>
              </v-tooltip>
            </v-btn>

            <v-btn icon @click.stop="displaydnsview()">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon slot="activator" color="green lighten-1" v-on="on">dns</v-icon>
                </template>

                <span>DNS</span>
              </v-tooltip>
            </v-btn>

            <v-btn icon v-if="currentAnalysis.features.iograph" @click.stop="displayiograph()">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="lime accent-2" slot="activator" v-on="on">show_chart</v-icon>
                </template>

                <span>Show Graph</span>
              </v-tooltip>
            </v-btn>
            <statisticsmenu></statisticsmenu>
          </v-btn-toggle>

          <miscmenu></miscmenu>

        </template>


      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import Statisticsmenu from "./menus/Statisticsmenu";
import Miscmenu from "./menus/Miscmenu";
import Analysisdebug from "./Analysisdebug";
import Analysisrecipe from "./Analysisrecipe";
import Filterbox from "./Filterbox";

import { commonmethods } from "@/common/common";
import { navigation } from "@/common/navigation";
import { LOGOUT, UPDATE_ANALYSIS, CHECK_AUTH } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";


export default {
  name: "navigation",

  watch: {
    "currentAnalysis.analysistabs": function (n, o) {
      this.toggle_exclusive = n
    },

  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > 15) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  components: {
    Analysisrecipe,
    Statisticsmenu,
    Miscmenu,
    Filterbox,
    Analysisdebug
  },
  data() {
    return {
      showDrawer: null,
      toggle_exclusive: 0,
      unindexed: { id: "none", indexername: "Unindexed" },

      pcap: { origname: "" },
      switchPanesVal: false,
      tcpfilter: false,
      nomodel: null,
      filterok: undefined,
      items: [
        { title: "Packet Analyzer", icon: "home", to: "/" },
        { title: "divider", icon: "", to: "PCAPs" },
        { title: "Public PCAPs", icon: "cloud_queue", to: "/pcaps/public" },
        { title: "My PCAPs", icon: "folder", to: "/pcaps/private" },
        { title: "Shared PCAPs", icon: "folder_shared", to: "/pcaps/shared" },
        { title: "divider", icon: "", to: "Analyses" },
        {
          title: "Public Analyses",
          icon: "present_to_all",
          to: "/analyses/public"
        },
        { title: "My Analyses", icon: "dvr", to: "/analyses/private" },
        /*
        { title: "divider", icon: "", to: "Add PCAPs" },
        { title: "Upload", icon: "cloud_upload", to: "/upload" },
        {
          title: "Google Drive",
          icon: "cloud_download",
          to: "/googledrive/list"
        },*/
        { title: "divider", icon: "", to: "Management" },
        // {
        //   title: "Coloring Rules",
        //   icon: "format_color_fill",
        //   to: "/coloringrules"
        // },

        { title: "Pricing", icon: "star", to: "/pricing" },
        { title: "Support", icon: "star", to: "/support" },
        {
          title: "Settings",
          icon: "settings",
          to: "/settings"
        }
      ],
      itemsanonusers: [
        { title: "Packet Analyzer", icon: "home", to: "/" },
        { title: "Public PCAPs", icon: "cloud", to: "/pcaps/public" },
        {
          title: "Public Analyses",
          icon: "present_to_all",
          to: "/analyses/public"
        },
        { title: "Login", icon: "exit_to_app", to: "/login" },
        { title: "Register", icon: "person_add", to: "/register" },
      ],
      itemsanonnousers: [
        { title: "Packet Analyzer", icon: "home", to: "/" },
        { title: "Public PCAPs", icon: "cloud", to: "/pcaps/public" },
        {
          title: "Public Analyses",
          icon: "present_to_all",
          to: "/analyses/public"
        },
        { title: "Pricing", icon: "dvr", to: "/pricing" },
        { title: "Support", icon: "star", to: "/support" },
        {
          title: "Settings",
          icon: "settings",
          to: "/settings"
        }
      ],
      itemsanon: []
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "avatarPic", "error", "analysismessage"]),

    computeditems() {
      let i = JSON.parse(JSON.stringify(this.items));
      if (this.currentAnalysis.recentlyused.length > 0) {
        let recently = [
          { title: "divider", icon: "", to: "Recently opened" }
        ]
        let xelems = 3

        if ("pcapClientHistory" in this.currentAnalysis.profiles.selected) {
          xelems = this.currentAnalysis.profiles.selected.pcapClientHistory
        }

        let rcu = this.currentAnalysis.recentlyused.slice(0, xelems)

        recently.push(...rcu)

        i.splice(5, 0, ...recently)
      }

      return i
    },
    availableviews() {
      var views = [this.unindexed]
      if (this.pcap && this.pcap.indices) {
        for (let index of this.pcap.indices) {
          views.push(index);
        }
      }

      return views;
    }
  },
  mounted() {
    this.currentAnalysis.showNavDrawer = true
    if (this.currentAnalysis.features.enableusers) {
      this.itemsanon = this.itemsanonusers
    } else {
      this.itemsanon = this.itemsanonnousers
    }
    /* set displayfilter externally not from navbar, e.g. by rightclick actions */
    // this.$eventHub.$on("displayfilter-set", filter => {
    //   this.displayfilter = filter;
    //   // forward event to packetlist
    //   this.$eventHub.$emit("displayfilter", this.displayfilter);
    // });
  },
  mixins: [commonmethods, navigation, displayfilter],
  methods: {
    resetAnalysis() {
      this.currentAnalysis.iostart = undefined
      this.currentAnalysis.ioend = undefined
      this.currentAnalysis.selectStartDate = undefined;
      this.currentAnalysis.selectEndDate = undefined;
      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;
    },
    displayconnectionsview() {
      this.navigateConnectionview(this.currentAnalysis.pcapid, this.currentAnalysis.index)
    },
    displaydnsview() {
      this.navigateDnsView(this.currentAnalysis.pcapid, this.currentAnalysis.index)
    },
    displaypacketview() {
      this.navigatePacketview(this.currentAnalysis.pcapid, this.currentAnalysis.index)
    },
    displayiograph() {
      this.navigateIOGraph(this.currentAnalysis.pcapid, this.currentAnalysis.index)

    },
    nextAnalysisStep: function () {
      this.$eventHub.$emit("next-step", null);
    },
    logout: function () {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "Packet Analyzer" }).catch(err => { }));
    }
  }
};
</script>

<style>
.v-input__slot {
  margin-top: 7px !important;
  min-height: 32px !important;
}

.v-text-field {
  padding-top: 14px;
}

.navigation-pcap-link {
  color: white !important;
  text-decoration: none !important;
}
</style>
