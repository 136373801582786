<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: stretch; margin-top: 20px; gap: 20px; max-width: 1040px;">
            <!-- Ranger PCAP Lab Section -->
            <div style="flex: 1; background-color: #333333; padding: 40px 30px; border-radius: 8px; color: white; display: flex; flex-direction: column; justify-content: space-between; max-width: 45%;">
                <div style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <img 
                        src="/images/rangerlab.png" 
                        alt="Ranger Lab Logo" 
                        style="width: 200px; margin-bottom: 20px; filter: invert(1);"
                    />
                    <h1 style="font-size: 24px; margin-bottom: 15px; text-align: center;">
                        Master Packet Analysis with Our Self-Paced CTF Labs
                    </h1>
                    <p style="font-size: 16px; margin-bottom: 20px; text-align: center;">
                        Gain hands-on experience in packet analysis with our expert-designed labs. <b>+25 labs</b>
                    </p>
                    <p style="font-size: 18px; margin-bottom: 20px; color: #ff9900; text-align: center;">
                        Use code <b style="font-family: 'Courier New', monospace;">RANGER50</b> at checkout to get <b>50% off</b>!<br>
                        <span style="font-size: 14px; color: #ffffff;">
                            Valid until {{ lastDayOfMonth }} ({{ daysLeft }} days left!)
                        </span>
                    </p>
                </div>
                <a href="https://labs.oripka.de/course/wsadven" target="_blank" 
                   style="text-decoration: none; color: white; background-color: #ff9900; padding: 12px 24px; border-radius: 5px; font-size: 18px; text-align: center;">
                    Start Your Lab Today
                </a>
            </div>

            <!-- AI Shark Section -->
            <div style="flex: 1; border: 2px solid #007bff; padding: 40px 30px; background-color: #f0f8ff; border-radius: 8px; display: flex; flex-direction: column; justify-content: space-between; max-width: 45%;">
                <div style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <img 
                        src="/images/aishark-logo.png" 
                        alt="AI Shark Logo" 
                        style="width: 120px; margin-bottom: 20px;"
                    />
                    <h2 style="color: #007bff; font-size: 24px; margin-bottom: 15px; text-align: center;">Automate Your Packet Analysis with AI Shark</h2>
                    <p style="color: #333; font-size: 16px; margin-bottom: 20px; text-align: center;">
                        Leverage AI-driven insights to analyze your PCAP files instantly. Save time and uncover critical data with the power of AI.
                    </p>
                    <p style="font-size: 18px; margin-bottom: 20px; color: #007bff; text-align: center;">
                        Use code <b style="font-family: 'Courier New', monospace;">HELPME55</b> at checkout to get <b>55% off</b>!<br>
                        <span style="font-size: 14px; color: #555;">
                            Valid until {{ lastDayOfMonth }} ({{ daysLeft }} days left!)
                        </span>
                    </p>
                </div>
                <a href="https://packetsafari.com/aishark" target="_blank" 
                   style="text-decoration: none; color: white; background-color: #007bff; padding: 12px 24px; border-radius: 5px; font-size: 18px; text-align: center;">
                    Try AI Shark Now
                </a>
            </div>
        </div>

        <!-- Scroll Down Message and Arrow -->
        <div style="text-align: center; margin-top: 40px;">
            <p style="font-size: 18px; color: #333;">
                If you're here just to analyze PCAPs, scroll down to upload.
            </p>
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <div style="animation: bounce 2s infinite;">
                    <svg width="40" height="40" viewBox="0 0 24 24" fill="#007bff" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 16l-6-6h12l-6 6z" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            today: new Date(),
        };
    },
    computed: {
        lastDayOfMonth() {
            const year = this.today.getFullYear();
            const month = this.today.getMonth() + 1; // Months are 0-indexed
            const lastDay = new Date(year, month, 0).toISOString().split('T')[0]; // Get last day of current month
            return lastDay;
        },
        daysLeft() {
            const year = this.today.getFullYear();
            const month = this.today.getMonth() + 1;
            const lastDay = new Date(year, month, 0);
            const diffTime = lastDay.getTime() - this.today.getTime();
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        },
    },
};
</script>
