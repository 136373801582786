<template>
    <v-container class="mt-5" style="display: flex; justify-content: center; align-items: stretch; gap: 20px;">
      <!-- Contact Support Section -->
      <v-card
        outlined
        class="pa-5"
        style="background-color: #f5f5f5; border-radius: 8px; border: 1px solid #ccc; text-align: center;"
      >
        <v-card-title class="headline" style="color: #333; font-size: 24px;">
          Contact Support
        </v-card-title>
        <v-card-text>
          <p style="color: #666; font-size: 16px; margin-bottom: 20px;">
            Need help? Reach out to our support team for assistance with any issues or questions.
          </p>
          <v-btn
            href="mailto:contact@packetsafari.com"
            style="background-color: #333333; color: white; padding: 12px 24px; border-radius: 5px; font-size: 18px;"
          >
            Email Support
          </v-btn>
        </v-card-text>
      </v-card>
  

    </v-container>
  </template>
  
  <script>
  export default {
    name: "SupportPage",
  };
  </script>
  
  <style scoped>
  /* Add any custom styles if needed */
  </style>
  