<template>
  <div
    style="display: flex; justify-content: center; align-items: stretch; margin-top: 20px; gap: 20px"
  >
    <!-- New Packet Safari Archive Section -->
    <div
      style="margin-top: 40px; padding: 40px 30px; background-color: #f5f5f5; border-radius: 8px; border: 1px solid #ccc; text-align: center;"
    >
      <h2 style="color: #333; font-size: 24px; margin-bottom: 15px;">
        Unlock 1 Year of Packet Safari Archive Access
      </h2>
      <p style="color: #666; font-size: 16px; margin-bottom: 20px;">
        Access an extensive archive of publicly uploaded PCAP files and enhance your
        understanding of packet data with curated examples.
      </p>
      <a
        href="/pricing"
       
        style="text-decoration: none; color: white; background-color: #333333; padding: 12px 24px; border-radius: 5px; font-size: 18px;"
      >
        Get 1-Year Access
      </a>
    </div>

    <!-- Bundle Offer Section -->
    <div
      style="margin-top: 40px; padding: 40px 30px; background-color: #fff3cd; border-radius: 8px; border: 1px solid #ffeeba; text-align: center;"
    >
      <h2 style="color: #856404; font-size: 24px; margin-bottom: 15px;">
        Exclusive Bundle: AI Shark, Packet Safari, and Ranger Lab Self-Paced Network Analysis Challenges
      </h2>
      <p style="color: #856404; font-size: 16px; margin-bottom: 20px;">
        Get all three products for at a discounted rate.
      </p>

      <a
        href="/pricing"
      
        style="text-decoration: none; color: white; background-color: #856404; padding: 12px 24px; border-radius: 5px; font-size: 18px;"
      >
        Grab the Bundle Now
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>
