import ApiService from "@/common/api.service";

// import usercache from '@/store/auth.module'

const ApiUser = {

    update(user){
        // usercache.reset()
        return ApiService.put('/user/'+user.id, user)
    },
    delete(userid){
        // usercache.reset()
        return ApiService.delete("/user", userid)
    },
    search(term){
        return ApiService.get("ajax/usersearch", term)
    },
    getbenefits(){
        return ApiService.query("getbenefits")
    },
    list(query){
        return ApiService.post("ajax/userlist", query)
    },
    getprofile(username){
        // usercache.reset()
        return ApiService.query("userprofile/"+username)
    },
    setcheckoutsession(session){
        // usercache.reset()
        return ApiService.put("user/setcheckoutsessionid", {
            sessionid: session
        })
    },
    setanalysisprofile(profile){
        // usercache.reset()
        return ApiService.put("user/analysisprofile", {
            profile: profile
        })
    },
    updaterecentlyused(id){
        return ApiService.post("ajax/recentlyused", { pcapid: id })
    },
    confirmsignupcode(params){
        return ApiService.post("auth/confirmsignupcode", params)
    },
    checkadmincreated(){
        return ApiService.query("auth/checkadmincreated")
    },
    resendsignupcode(username){
        return ApiService.post("auth/resendcode", username)
    },
    forgotpassword(user){
        return ApiService.post("auth/forgotpassword", user)
    },
    setnewpassword(params){
        return ApiService.post("auth/setnewpassword", params)
    },

}

export default ApiUser