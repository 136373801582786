<template>
  <v-row align="center" justify="center" style="margin-top: 60px; margin-bottom: 200px; padding-bottom: 200px;">
    <!-- Existing Subscription Section -->
    <div v-if="hasSubscription || paymentSuccess" class="subscription-container">
      <v-card class="mx-auto pa-5" max-width="600">
        <!-- Dynamic Title Based on Payment Success -->
        <v-card-title class="headline">
          <template v-if="paymentSuccess">
            🥳 Congratulations on Your Purchase!
          </template>
          <template v-else>
            🎉 You Already Have a Subscription!
          </template>
        </v-card-title>
        <v-card-text>
          <p>
            You currently have an active subscription. Enjoy these benefits:
          </p>

          <v-btn class="ma-4" color="primary" :to="`/pcaps/public`">
            🎉 Access full PCAP archive 
          </v-btn>

          <v-btn class="ma-4" color="primary" :to="`/`">
            🎉 Upload private PCAP up to 5 MB
          </v-btn>


          <!-- Benefits List -->
          <v-divider class="my-4"></v-divider>
          <v-subheader>Your Benefits:</v-subheader>
          <v-list>
            <v-list-item v-for="(benefit, index) in currentSubscriptionBenefits" :key="index">
              <v-list-item-icon>
                <v-icon color="green">mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ benefit }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-container v-if="showVouchers">
            <v-card-title v-if="showVouchers">Your Bundled Products</v-card-title>

            <v-alert type="info" outlined>
              <b>The bundled products require separate accounts. To access the
                bundled products, follow these steps</b>
            </v-alert>

            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <p>1. Create a separate account for AI Shark and the Ranger Labsfix at
                    <a href="https://www.packetsafari.com/signup" target="_blank" class="text-primary">this link</a>.
                  </p>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <p>2. Visit the pricing page at
                    <a href="https://www.packetsafari.com/aishark/buy" target="_blank" class="text-primary">this
                      link</a>
                    and click <b>"Buy AI Shark Pro Credits"</b> (this option is available only when logged in).
                  </p>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <p>3. Use the AI Shark 100% discount code (provided below) in the popup where it says <b>"Add
                      discount."</b>
                    Enter your details and proceed.
                  </p>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <p>4. You now have access to <b>AI Shark Pro</b>.</p>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <p>5. Log in to the
                    <a href="https://labs.oripka.de/signin" target="_blank" class="text-primary">lab platform</a>
                    using the same username and password created in Step 1.
                  </p>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <p>6. On the
                    <a href="https://labs.oripka.de/course/wsadven" target="_blank" class="text-primary">Ranger Lab
                      course
                      page</a>,
                    click <b>"Get access"</b> (you must be logged in to purchase the course).
                  </p>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <p>7. Use the Ranger Labs 100% discount code (provided below) in the popup where it says <b>"Add
                      discount."</b>
                    Enter your details and proceed.
                  </p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <p>8. Go to <a href="https://labs.oripka.de/mycourses">My labs</a>
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
          </v-container>

          <v-list v-if="showVouchers">
            <v-list-item v-for="(voucher, index) in vouchers" :key="index">
              <v-list-item-icon>
                <v-icon color="blue">mdi-tag</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ voucher.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  Feature: {{ voucher.feature }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-btn small @click="copyVoucher(voucher.code)">Copy Code</v-btn>
            </v-list-item>
          </v-list>


                    <!-- Link to Profile for More Details -->
                    <v-btn class="ma-4" color="primary" :to="`/profile/${this.currentUser.username}`">
            View Your Profile
          </v-btn>

        </v-card-text>
      </v-card>
    </div>

    <!-- Pricing Cards Section -->
    <template v-else>
      <div style="padding-bottom: 80px; font-size: 28px;">
        Packet Safari Products
      </div>
      <v-container style="padding-bottom: 200px;">
        <v-row>
          <v-col v-for="price in prices" :key="price.priceId" cols="12" sm="12" md="3">
            <v-card class="mx-auto pricing-card" :color="price.isBundle ? 'light-blue lighten-5' : 'white'" outlined
              elevation="2">
              <v-img :src="price.img" height="200px"></v-img>

              <v-card-title>
                {{ price.name }}
              </v-card-title>

              <div class="price-display">
                {{ price.price }}
              </div>

              <v-card-subtitle>
                {{ price.description }}
              </v-card-subtitle>

              <v-card-text>
                {{ price.detaildescription }}
                <v-list dense :color="price.isBundle ? 'light-blue lighten-5' : 'white'">
                  <v-list-item v-for="(item, i) in price.features" :key="i">
                    <v-list-item-icon>
                      <v-icon color="green">mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <!-- Pricing actions -->
                <v-btn v-if="price.link" :href="price.link" target="_blank" rel="noopener noreferrer"
                  color="blue lighten-2" text>
                  Learn More
                </v-btn>

                <v-btn v-else-if="currentUser && hasSubscription" @click="showSubscriptionMessage"
                  color="blue lighten-2" text>
                  You already have an active subscription
                </v-btn>

                <v-btn v-else @click="openCheckout(price)" color="blue lighten-2" text>
                  Get access
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Snackbar for Notifications -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top right timeout="3000" @click:close="closeSnackbar">
      {{ snackbar.message }}
      <v-btn color="white" text small @click="closeSnackbar">
        Close
      </v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import ApiUser from "@/common/api/user";
export default {
  name: "Pricing",
  data() {
    return {
      currentSubscriptionBenefits: [],
      paymentLink: "",
      paymentSuccess: false, // Tracks payment success
      vouchers: [], // Array to hold multiple vouchers
      showVouchers: false, // Show vouchers based on purchase
      successBenefits: [
        // Default list of benefits; will be updated based on purchase
      ],
      prices: [
        {
          priceId: "pri_01hq0yz6edjrbnv0x07wc80gge", // Price ID for PacketSafari
          price: "150$",
          name: "PacketSafari Analyser + Archive",
          description:
            "1 Year Access to PacketSafari PCAP archive with good descriptions",
          img: "/pricingcard-packetsafari.jpeg",
          detaildescription:
            "One-year access to the comprehensive PacketSafari PCAP archive.",
          features: [
            { icon: "mdi-check", text: "Access to +5000 PCAPs" },
            { icon: "mdi-check", text: "Maximum upload size for PCAPs 5 MB" },
            { icon: "mdi-check", text: "Total storage of 200MB" },
            { icon: "mdi-check", text: "PCAPs are not shared with public" },
            { icon: "mdi-check", text: "No subscription" },
          ],
          isBundle: false,
        },
        {
          // priceId: "price_aishark_001", // Price ID for AI Shark
          price: "89$",
          name: "AI Shark",
          description: "50 AI-automated PCAP analysis runs",
          img: "/pricingcard-aishark.png",
          detaildescription:
            "Unlock the automatic analysis of small PCAPs with AI Shark.",
          features: [
            { icon: "mdi-check", text: "Automatic PCAP analysis" },
            { icon: "mdi-check", text: "AI-driven insights" },
            { icon: "mdi-check", text: "Works best for very small PCAPs" },
            { icon: "mdi-check", text: "50 analysis runs" },
          ],
          link: "https://www.packetsafari.com/aishark",
          isBundle: false,
        },
        {
          // priceId: "price_rangerlabs_001", // Price ID for Ranger Labs
          price: "200$",
          name: "Ranger: A self-paced PCAP lab",
          description: "Advanced network analysis labs",
          img: "/pricingcard-ranger.png",
          detaildescription:
            "Real-world case study CTF hands-on PCAP analysis labs for advanced analysts",
          features: [
            { icon: "mdi-check", text: "Advanced network analysis" },
            { icon: "mdi-check", text: "Comprehensive case studies" },
            { icon: "mdi-check", text: "Hands-on lab environments" },
            { icon: "mdi-check", text: "Hint system" },
          ],
          link: "https://labs.oripka.de/course/wsadven",
          isBundle: false,
        },
        {
          priceId: "pri_01jhmj5py0wknn4bmnbeg3xg15", // Price ID for Bundle
          price: "350$",
          name: "Bundle - Full Access",
          description: "Full access to all products",
          img: "/pricingcard-bundle.png",
          detaildescription:
            "Access the whole PacketSafari PCAP archive (one year access), Ranger Labs, and AI Shark",
          features: [
            { icon: "mdi-check", text: "PacketSafari Analyser + Archive" },
            { icon: "mdi-check", text: "AI Shark: 50 analysis runs" },
            { icon: "mdi-check", text: "Ranger Lab" },
            { icon: "mdi-check", text: "No subscription" },
          ],
          discount: "20% off",
          isBundle: true, // Flag to identify bundle
        },
      ],
      snackbar: {
        show: false,
        message: "",
        color: "success",
      },
    };
  },
  created() {
    this.checkRouteParams();
  },
  watch: {
    // Watch for changes in the route to rerun fetchBenefits
    $route(to, from) {
      if (to.path === from.path) {
        this.checkRouteParams();
      }
    },
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),

    // Define hasSubscription as a computed property
    hasSubscription() {
      if (!this.currentUser || !this.currentUser.deactivation_date || this.currentUser.ratelimiting.subscription === 'unsubscribed_user') {
        return false; // No user or no deactivation date means no active subscription
      }

      const deactivation = new Date(this.currentUser.deactivation_date);
      const today = new Date();

      // Check if the current date is before or on the deactivation date
      return today <= deactivation;
    },
  },
  methods: {
    checkRouteParams() {
      const urlParams = new URLSearchParams(this.$route.query);
      if (urlParams.has("success")) {
        this.paymentSuccess = true;
        this.fetchBenefits(); // Fetch benefits after successful payment
      }
      if (this.hasSubscription) {
        this.fetchBenefits(); // Fetch benefits if user already has a subscription
      }
    },
    async fetchBenefits() {
      try {
        const response = await ApiUser.getbenefits();
        const benefits = response.data;

        const codes = benefits.discount_codes;
        console.log(codes);
        // Map API benefits to U
        this.currentSubscriptionBenefits = codes.map(
          (benefit) => `${benefit.title} (${benefit.feature})`
        );
        this.vouchers = codes.map((benefit) => ({
          code: benefit.code,
          title: benefit.title,
          signupLink: benefit.signupLink,
          feature: benefit.feature,
        }));

        if (this.hasSubscription) {
          this.currentSubscriptionBenefits = [
            "1y Access to the entire PacketSafari PCAP archive",
            "200 MB of storage",
            "5 MB upload limit for PCAPs",
            "PCAPs are not shared with public",
            "Regularly updated content",
            "Higher download limits",
          ];
        }

        if(this.vouchers.length > 0){
          this.showVouchers = true;
        }

      } catch (error) {
        console.error("Error fetching benefits:", error);
        this.snackbar.message = "Failed to load benefits.";
        this.snackbar.color = "error";
        this.snackbar.show = true;
      }
    },
    openCheckout(selectedPrice) {
      if (!this.currentUser) {
        this.$router.push({ name: "Register" });
        return;
      }

      // Initialize Paddle if not already done
      if (!window.Paddle) {
        console.error("Paddle is not loaded.");
        return;
      }

      // Append ?success=true&product=priceId to the current URL for redirection
      const successurl = `${window.location.origin}${this.$route.path}?success=true&product=${selectedPrice.priceId}`;

      window.Paddle.Setup({
        // via environment variable PADDLE_CLIENT_SIDE_TOKEN
        token: process.env.VUE_APP_PADDLE_CLIENT_SIDE_TOKEN,
      });

      window.Paddle.Checkout.open({
        email: this.currentUser.email,
        settings: {
          displayMode: "overlay",
          theme: "dark",
          successUrl: successurl,
          showAddTaxId: "true",
        },
        items: [{ priceId: selectedPrice.priceId, quantity: 1 }],
        customData: {
          userId: this.currentUser.username,
        },
      });
    },
    isExpired(deactivationDate) {
      if (!deactivationDate) return false;
      const deactivation = new Date(deactivationDate);
      const today = new Date();
      return today > deactivation;
    },
    copyVoucher(code) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          this.snackbar.message = "Voucher code copied to clipboard!";
          this.snackbar.color = "success";
          this.snackbar.show = true;
        })
        .catch(() => {
          this.snackbar.message = "Failed to copy voucher code.";
          this.snackbar.color = "error";
          this.snackbar.show = true;
        });
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped>
.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 800px;
  /* Set a consistent height for all cards */
  max-height: 800px;
}

.price-display {
  font-size: 24px;
  font-weight: bold;
  margin: 0 16px 16px 16px;
}

.v-card {
  transition: transform 0.3s;
}

.v-card:hover {
  transform: scale(1.05);
}
</style>
